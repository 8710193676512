import NotificationsAuditsModal from "@/components/inventory/Audit/notificationsAudits/NotificationAudit.modal";
import { EventBus } from "@/event-bus";
import { Audit, AuditStatus } from "@/interfaces/audit";
import { PusherNotification } from "@/interfaces/notification";
import { User } from "@/interfaces/user";
import { pusherEvents } from "@/internal";
import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";

interface Payload {
  has_errors: boolean;
  operation_uid: string;
  summary: {
    status: AuditStatus;
  };
}
@Component({
  render: c => c("router-view"),
  inject: ["$changes"]
})
export default class AuditComponent extends Vue {
  @Getter("user", { namespace: "AuthModule" })
  public user!: User;
  public isAuditClosed = false;
  public isCurrentUser = false;
  public releaseAction() {
    if (!this.isAuditClosed) {
      EventBus.$emit("reloadAudits");
    }
  }

  public isChangedForOtherUser(response: PusherNotification) {
    EventBus.$emit("closePreviousNotication");
    this.isAuditClosed = false;
    const auditChanged = response.message[response.message.length - 1]
      .payload as Payload;
    this.isCurrentUser =
      response.message[response.message.length - 1].user.id === this.user.id;
    const action = response.message[response.message.length - 1].action;

    if (auditChanged.operation_uid === this.$route.params.operationId) {
      if (action === "AuditReportFailedOnClose") {
        if (auditChanged.has_errors) {
          return this.setErrorMtrcInItems();
        } else {
          return this.setGeneralErrorMtrc();
        }
      }
      this.setSuccessfulClose(auditChanged);
    }

    return (
      auditChanged.operation_uid === this.$route.params.operationId ||
      this.$route.name !== "audit-edit"
    );
  }

  public created() {
    this.$changes.watch(
      [pusherEvents.batchAudit, pusherEvents.batchAuditReservation],
      this.releaseAction,
      this.isChangedForOtherUser,
      NotificationsAuditsModal,
      true
    );
  }

  private setGeneralErrorMtrc() {
    this.setMtrcLoadingEvent({
      show: true,
      errorList: [{ message: this.$t("audit.mtrc_error").toString() }],
      className: "text-xs-center"
    });
    EventBus.$emit("reloadDetailAudit");
    return false;
  }

  private setErrorMtrcInItems() {
    this.setMtrcLoadingEvent({
      show: true,
      errorList: [
        { message: this.$t("audit.generic_msg1").toString() },
        { message: this.$t("audit.generic_msg2").toString() }
      ],
      className: "text-xs-center"
    });
    if (this.isCurrentUser) {
      EventBus.$emit("reloadDetailAudit");
      return false;
    }
    return true;
  }

  private setMtrcLoadingEvent(config: {
    show: boolean;
    errorList?: Array<{ message: string }>;
    className?: string;
  }) {
    EventBus.$emit("mtrcLoadingEvent", config);
  }

  private setSuccessfulClose(auditChanged: Payload) {
    this.setMtrcLoadingEvent({
      show: false
    });
    this.isAuditClosed = auditChanged.summary.status === AuditStatus.CLOSED;
    if (this.isAuditClosed && this.isCurrentUser) {
      this.$router.push({ name: "audit" });
    }
  }
}

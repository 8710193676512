import ConfirmModalComponent from "@/components/sharedComponents/confirm/confirm.component";
import { EventBus } from "@/event-bus";
import { Audit } from "@/interfaces/audit";
import { PusherNotification } from "@/interfaces/notification";
import { User } from "@/interfaces/user";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import Template from "./NotificationAudit.template.vue";

@Component({
  mixins: [Template]
})
export default class NotificationsAuditsModal extends Vue {
  @Getter("user", { namespace: "AuthModule" })
  public user!: User;
  @Prop({ required: true }) public data!: PusherNotification;
  @Prop() public actionResolve!: () => void;
  public currentAudit: Audit | null = null;
  public actionAudit = "";
  public configModal: {
    [key: string]: { msg: string; button: boolean; action: () => void };
  } | null = null;
  public currentConfigModal: {
    msg: string;
    button: boolean;
    action?: () => void;
    label?: string;
  } | null = null;

  public resolve() {
    this.currentConfigModal!.action!();
    this.$emit("resolve");
  }

  public actionOpened() {
    if (this.currentAudit!.real_time_audit) {
      this.modalAlert(this.currentAudit!, {
        action: this.goEditAudit,
        title: "audit.real_time_audit",
        body: "audit.entry_real_time_msg"
      });
    } else {
      this.goEditAudit(this.currentAudit!);
    }
  }

  public goEditAudit(currentAudit: Audit) {
    this.$router.push({
      name: "audit-edit",
      params: {
        operationId: currentAudit.operation_uid
      }
    });
  }

  public modalAlert(
    currentAudit: Audit,
    data: {
      action: (audit: Audit) => void;
      title: string;
      body: string;
    },
    icon: string = "fal fa-exclamation-triangle"
  ) {
    this.$modals
      .load(
        ConfirmModalComponent,
        {
          size: "normal",
          positionY: "center"
        },
        {
          modalData: {
            title: data.title,
            body: this.$t(data.body),
            icon
          }
        }
      )
      .then(
        () => {
          data.action(currentAudit);
        },
        () => {
          /** Nothing to do */
        }
      );
  }

  public reject() {
    this.$emit("reject");
  }

  public created() {
    EventBus.$on("closePreviousNotication", () => {
      this.reject();
    });
  }

  public mounted() {
    const actionAudit = this.data.message[0].action;
    this.currentAudit = this.data.message[0].payload as Audit;
    const isCurrentUser = this.data.message[0].user.id === this.user.id;
    const routeIsAuditDetails = this.$route.name === "audit-edit";
    if (routeIsAuditDetails && isCurrentUser) {
      EventBus.$emit("reloadDetailAudit");
    } else {
      this.actionResolve();
    }
    const configModal: {
      [key: string]: {
        msg: string;
        button: boolean;
        action?: () => void;
        label?: string;
      };
    } = {
      AuditOpened: {
        msg: this.$t(
          isCurrentUser
            ? "audit.notification_opened"
            : "audit.notification_created",
          {
            auditUid: this.currentAudit.operation_uid
          }
        ).toString(),
        button: isCurrentUser,
        action: this.actionOpened,
        label: this.$t("audit.open_audit").toString()
      },
      AuditClosed: {
        msg: this.$t("audit.notification_closed", {
          auditUid: this.currentAudit.operation_uid
        }).toString(),
        button: !isCurrentUser,
        label: this.$t("audit.reload").toString(),
        action: () => {
          EventBus.$emit("reloadDetailAudit");
        }
      },
      AuditResumed: {
        msg: this.$t(
          routeIsAuditDetails
            ? "audit.notification_resumed_in_route"
            : "audit.notification_resumed",
          {
            auditUid: this.currentAudit.operation_uid
          }
        ).toString(),
        button: !isCurrentUser,
        label: routeIsAuditDetails
          ? this.$t("audit.reload").toString()
          : this.$t("audit.open_audit").toString(),
        action: routeIsAuditDetails
          ? () => {
              EventBus.$emit("reloadDetailAudit");
            }
          : this.actionOpened
      },
      AuditPaused: {
        msg: this.$t("audit.notification_paused", {
          auditUid: this.currentAudit.operation_uid
        }).toString(),
        button: !isCurrentUser,
        label: this.$t("audit.reload").toString(),
        action: () => {
          EventBus.$emit("reloadDetailAudit");
        }
      },
      AuditCanceled: {
        msg: this.$t("audit.notification_canceled", {
          auditUid: this.currentAudit.operation_uid
        }).toString(),
        button: false
      },
      AuditChanged: {
        msg: this.$t("audit.notification_generic", {
          auditUid: this.currentAudit.operation_uid
        }).toString(),
        button: !isCurrentUser,
        label: this.$t("audit.reload").toString(),
        action: () => {
          EventBus.$emit("reloadDetailAudit");
        }
      }
    };

    this.currentConfigModal =
      configModal[actionAudit] || configModal.AuditChanged;
  }
}
